import React from "react"
import { Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase";
import Layout from "../components/layout"
import CompanyList from "../components/CompanyList"

const IndexPage = () => {
  const { isLoading, isLoggedIn, profile } = useAuth();
  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  else if (isLoggedIn) {
    return (
      <Layout>
        <h1>Welcome!</h1>
        <ul>
          <li><Link to="/companies">All companies</Link></li>
          <li><Link to="/members">All members</Link></li>
          <li style={{display:'none'}}><Link to="/sessions">Session Feed</Link></li>
        </ul>
        <h2>Companies you manage</h2>
        <div style={{maxWidth: 1000, width: '100%'}}>
          <CompanyList filterByOwnerEmail={profile.email} />
        </div>
      </Layout>
    )
  }
  else {
    return (
      <Layout>
        <h1>Please log in</h1>
        <Link to="/login">Log in</Link>
      </Layout>
    )
  }
}

export default IndexPage
